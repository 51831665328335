<!-- <div class="accountbox-wrapper is-visible">
    <div class="body-overlay"></div>
    <div class="accountbox ">
        <div id="cooking">
            <div class="bubble"></div>
            <div class="bubble"></div>
            <div class="bubble"></div>
            <div class="bubble"></div>
            <div class="bubble"></div>
            <div id=area>
                <div id="sides">
                    <div id="pan"></div>
                    <div id="handle"></div>
                </div>
                <div id="pancake">
                    <div id="pastry"></div>
                </div>
            </div>
        </div>
    </div>
</div>
 -->

<div style="height: 95vh;overflow: hidden;">
    <div class="card is-loading">
        <div class="image"></div>
        <div class="sideBar">
            <p></p>
        </div>
        <div class="content">
            <h2 style="height: 10vh;;"></h2><br>
            <h2></h2><br>
            <h2></h2><br>
        </div>
    </div>
</div>