import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'showPriceVariation',
  standalone: true,
  pure: false
})
export class ShowPriceVariationPipe implements PipeTransform {

  transform(value: any, place:string): string {
  
    let price:string = "";
    if(place == 'menu'){
      value != "0" ?  price = value : price = "( Variation Price )";
    } else {
      // console.log(value);
      if(value.addonVariation){
        price = value.addonVariation.varients.price
      } else {
        price = value.item.price
      }
      // value.item.price != "0" ? price = value.item.price : price = value.addonVariation.varients.price;
    }
   
    return price;
  }

}
