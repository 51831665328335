import { Component, Input, OnInit, EventEmitter, Output, OnChanges } from '@angular/core';
import { RouterLink } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { SharedService } from '../../../core/services/shared.service';
import { ApiService } from '../../../core/services/api.service';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [RouterLink, FormsModule, CommonModule],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent implements OnInit, OnChanges {

  @Input() pageName: string = '';
  @Input() triggerShowBranch: boolean = false;
  showSearch: boolean = false;
  orderQuantity: number = 0;
  veg: boolean = false;
  headerSearchKeyword: any = '';
  @Output() filterItem = new EventEmitter<any>();
  @Output() filterVeg = new EventEmitter<any>();
  @Output() branchId = new EventEmitter<any>();
  showclearIcon: boolean = false;
  orderNowText: string = "Order Now";
  customerDetails: any = [];
  orderHistory: any = [];
  showTracking: boolean = false;
  support: boolean = false;
  storeOpt: boolean = false;
  displayStoreOption: boolean = false;

  vendorData: any = [];
  branchName: any;
  branchContact: any;
  supportContact:any;
  conformChangeBranch: boolean = false;
  availableBranches: any = [];
  showMobileSearch: boolean = false;

  constructor(public sharedData: SharedService, public apiService: ApiService) { }

  ngOnInit(): void {
    this.pageName == "food-menu" ? this.showSearch = true : this.showSearch = false;
    this.pageName == "cart" ? this.orderNowText = "Add more" : this.orderNowText = "Order Now";

    // console.log(this.pageName);
   

    let custDetail: any = localStorage.getItem('customerDetails');
    let vendorDetail: any = localStorage.getItem('vendorData');
    let branchData:any = localStorage.getItem("availableBranches");
    this.availableBranches = JSON.parse(branchData)
   
    const selectedLocation = localStorage.getItem('selectedLocation');
    // console.log(this.availableBranches, selectedLocation);
    if (selectedLocation != null) {
      this.sharedData.getbranchData().subscribe((data: any) => {
        // console.log('brancgdata', data);
        this.vendorData = data;
        if (this.vendorData?.restaurantDetails != undefined) {
          let restId: any = localStorage.getItem("selectedRestId");
          console.log(this.displayStoreOption,'this.displayStoreOption',this.vendorData.restaurants.length);
          //Consider as single branch if only one branch is available
          if(this.vendorData.restaurants.length > 1){
             this.displayStoreOption = true;
          } else if(this.vendorData.restaurants.length == 1){
            this.displayStoreOption = false;
            localStorage.setItem('branchType','single');
            localStorage.setItem('selectedRestId',this.vendorData.restaurantDetails[0].id);
            this.branchId.emit(this.vendorData.restaurantDetails[0].id)
          }

          if (restId == undefined) {
            this.ShowBranch();
          } else {
            this.vendorData.restaurantDetails.forEach((data: any) => {
              if (data.id == restId.toString()) {
                console.log(data);
                
                this.branchName = data.restaurantName;
                this.branchContact = data.contact;
                this.supportContact = data.supportContact;
              }
            })
          }
        }
      });
    }



    this.customerDetails = JSON.parse(custDetail);
    //  this.vendorData = JSON.parse(vendorDetail);
    this.getOrderHistory();

   
    

  }
  ngOnChanges():void{
   
    if(this.triggerShowBranch){
      this.ShowBranch();
    } else {
      this.storeOpt = false;
    }
  }


  ShowBranch(): void {
    // console.log('this.vendorData ',this.vendorData );
    if (this.vendorData.restaurantDetails?.length > 1 && (this.pageName == "food-menu" || this.pageName == "deliveryMode")) {
      // this.displayStoreOption = true;
      localStorage.setItem('branchType','multi');
      this.storeOpt = true;
    }
    // if(this.vendorData.restaurantDetails.length == 1 ){
    //   this.displayStoreOption = false;
    //   localStorage.setItem('branchType','single');
    //   localStorage.setItem('selectedRestId',this.vendorData.restaurantDetails[0].id);
    //   this.branchId.emit(this.vendorData.restaurantDetails[0].id)
    // }
  }
  confirmChangeBranch() {
    this.ShowBranch();
    // localStorage.removeItem('foodBasket');
    this.conformChangeBranch = false;
  }
  changeBranch(): void {
    if (this.pageName == "food-menu" || this.pageName == "deliveryMode")
      this.conformChangeBranch = true;

  }
  vegMenuFilter(): void {
    this.filterVeg.emit(this.veg);
  }

  categoryFilter(): void {
    this.headerSearchKeyword != "" ? this.showclearIcon = true : this.showclearIcon = false;
    this.filterItem.emit(this.headerSearchKeyword);

  }

  clearFiler(): void {
    this.headerSearchKeyword = "";
    this.categoryFilter();
  }

  toggleSupport(): void {
    this.support = !this.support;
  }

  selectBranch(index: number): void {
    console.log(index,this.vendorData.restaurantDetails[index].id);
    if(this.availableBranches.includes(this.vendorData.restaurantDetails[index].id)){
      if (index == 99) {
        this.ShowBranch();
      } else {
        localStorage.setItem('selectedRestId', this.vendorData.restaurantDetails[index].id);
        this.storeOpt = false;
        this.branchId.emit(this.vendorData.restaurantDetails[index].id);
        this.branchName = this.vendorData.restaurantDetails[index].restaurantName;
        this.branchContact = this.vendorData.restaurantDetails[index].contact;
        this.supportContact = this.vendorData.restaurantDetails[index].supportContact;
      }
    }

  }


  /**
  * To fetch order history
  */
  getOrderHistory(): void {
    if (this.customerDetails) {
      const orderStaus = ['PAID', 'ACCEPTED', 'MARK_FOOD_READY', 'OUT_FOR_PICKUP', 'REACHED_PICKUP', 'PICKED_UP', 'OUT_FOR_DELIVERY', 'REACHED_DELIVERY']
      this.apiService.getMethod(`/order?customerId_eq=${this.customerDetails.id}`).subscribe({
        next: (reponse) => {
          this.orderHistory = reponse.data;
          if (orderStaus.includes(this.orderHistory[0]?.status)) {
            this.showTracking = true;
          }
        },
        error: (error) => { console.log(error) }
      })
    }
  }



}
