import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'inflatePrice',
  standalone: true
})
export class InflatePricePipe implements PipeTransform {

  transform(value: any, percentage:any): unknown {
    // const infaltedPrice:any = Math.ceil(parseInt(value) / (1 - parseInt(percentage) / 100));
    const infaltedPrice:any = parseFloat(value) - ((percentage / 100)*parseFloat(value));
    return infaltedPrice.toFixed(2);
  }

}
