import { Component, OnInit, isDevMode } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { HeaderComponent } from "./theme/components/header/header.component";
import { FooterComponent } from "./theme/components/footer/footer.component";
import { WebSocketService } from './core/services/websocket.service';
import { StoreClosedComponent } from './store-closed/store-closed.component';

import { ApiService } from './core/services/api.service';
import { SharedService } from './core/services/shared.service';

@Component({
    selector: 'app-root',
    standalone: true,
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss',
    imports: [CommonModule, RouterOutlet, HeaderComponent, FooterComponent,StoreClosedComponent]
})
export class AppComponent implements OnInit{
  title = 'Ankapur';

  constructor(private webSocketService: WebSocketService, public apiService: ApiService,  public sharedData: SharedService,) { }

  ngOnInit(): void {
    
    let url = window.location.href;
    let domain = new URL(url).origin;
    console.log('isDevMode',isDevMode());
    if(isDevMode()){
      domain ="https://rasyumm.hyperapps.cloud"
      // domain ="https://order.madhapurbawarchi.com"
      // domain ="https://31.178.75.55:9090"
    }
    // console.log(window.location.href, domain);
    this.apiService.getMethod(`/partner?domain_eq=${domain}`).subscribe({
      next: (response) => {
        // console.log('',response.data);
        this.sharedData.sendbranchData(response.data[0]);
        localStorage.setItem('vendorData',JSON.stringify(response.data[0]));
      },
      error: (error) => { console.log(error) }
    })
  }
  
}
