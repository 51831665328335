 @if ((mainLoading || restaurentLoading)&&!showDeliveryMode) {
<app-loader></app-loader>} @if(showDeliveryMode){
<app-deliverymode (closeDelivery)="closeDeliveryMode($event)"></app-deliverymode>
} @else if(!showDeliveryMode){

<app-header [pageName]="currentPage" [triggerShowBranch]="showBranch" (filterVeg)="getVegFilter($event)" (filterItem)="getFilterItem($event)" (branchId)="getBranchId($event)"></app-header>

<div class="main-window mt-8 mb-lg-14 mb-8" [ngClass]="{ 'hiddenMW': restaurentLoading || mainLoading }">
    <div class="container-fluid">
        <div class="row">
            @if (!searchActive) {
            <div class="col-lg-3 col-md-4 mb-6 mb-md-0  hideXs" style="padding-right:0px ;">
                <div class="food__sidebar" style="position: sticky;top: 165px;">
                    <div class="food__category__area sideMenuStyle">
                        <h4 class="side__title">Menu
                            <i class="fa fa-bars menuBar" style="float: right;font-size:15px; margin-top: 6px;"></i>
                        </h4>

                        <ul class="food__category">
                            @for (category of menuResponseFiltered; track $index) {
                            <li [ngClass]="{ 'selected-menu-list': $index == activeSection  }" (click)="selectCategory(category, $index);scrollToSection($index)">
                                <a style="color: #020101 !important;">{{category!.categoryName | emptyStringCheck:'Category'}}</a>
                            </li>
                            }
                        </ul>
                    </div>
                </div>
            </div>
            }

            <div class="col-lg-9 col-md-12" style="padding: 0;">
                @if (!searchActive){
                <i class="fa fa-bars menuBar" (click)="openMenu();showMenu=!showMenu"> Menu</i>
                <div class="bottom-sheet-overlay" [class.active]="isMenuOpen" (click)="closeMenu()"></div>
                <div class="bottom-sheet" [class.active]="isMenuOpen">
                    <div class="bottom-sheet-header">
                        <h3>Categories</h3>
                        <button (click)="closeMenu()" class="close-button">&times;</button>
                    </div>
                    <div class="bottom-sheet-content">
                        @for (category of menuResponseFiltered; track $index) {
                        <a (click)="navigateTo($index)">{{category!.categoryName | emptyStringCheck:'Category'}}</a> }
                    </div>
                </div>
                }

                <div class="product-wrapper product-wrapper-container">
                    @if (!searchActive){
                    <div class="food__search">
                        <div class=" location-wrapper side__title hideXs">
                            <div class="row " style="align-items: flex-end;">
                                <div class=" col-md-2 col-sm-12  col-lg-2">
                                    <span class="service-category"><i class="fa fa-map-marker"
                                    style="color: #d50c0d;padding-right:10px;font-size: 24px;;"
                                    aria-hidden="true"></i>Location:
                                </span>
                                </div>
                                <div class=" col-md-8 col-sm-12  col-lg-8" style="overflow: hidden;">
                                    <span class="address cls-ellipsis">{{address}}</span>
                                </div>
                                <div class=" col-md-2 col-sm-12  col-lg-2" style="    text-align: end;">
                                    <div class="grid__show__btn grid__show__btn11" style="    float: unset;">
                                        <a class="food__btn change-btn" (click)="addrSearch = true">Change</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h4 class="side__title st showXs">
                            <!-- <div class="location-wrapper hideXs">
                                <span class="service-category"><i class="fa fa-map-marker"
                                        style="color: #d50c0d;padding-right:10px;font-size: 24px;;"
                                        aria-hidden="true"></i>Location
                                    : </span>
                                <span class="address cls-ellipsis">{{address}}</span>
                                <div class="grid__show__btn grid__show__btn11">
                                    <a class="food__btn change-btn" (click)="addrSearch = true">Change</a>
                                </div>
                            </div> -->


                            <div class="location-wrapper showXs" style="margin-top:15px;">
                                <div class="row">

                                    <span class="address" style="display: inline-flex;"><i class="fa fa-map-marker" style="color: #d50c0d;font-size: 24px;"
                                        aria-hidden="true"></i>{{address}}</span>
                                    <div class="grid__show__btn" style="margin-top: 20px;"><a class="food__btn change-btn" (click)="addrSearch = true">Change</a></div>

                                </div>



                            </div>
                        </h4>
                    </div>
                    } @if (noItemFound) {
                    <span class="no-found">Sorry No Items found..</span> } @if (!noItemFound && searchActive){
                    <div class="food__search mt--30">
                        <h4 class="side__title">
                            <!-- {{filteredCategory | titlecase}} -->
                            Search Results
                        </h4>
                    </div>

                    <div class="row">
                        @for (item of menuResponseFilterApplied; track $index) {

                        <div class="col-md-6">
                            <div class="food__menu__container">
                                <div class="food__menu__inner d-flex flex-wrap flex-md-nowrap flex-lg-nowrap">
                                    <div class="food__menu__details">
                                        <div class="food__menu__content" [ngClass]="{ 'out-of-stock': item.active === '0', 'in-stock': item.active === '1' }">
                                            <h2> <i>
                                                    @if (item.itemAttributeId == '1') {
                                                        <svg  xmlns="http://www.w3.org/2000/svg" width="16" height="16" version="1.1">
                                                            <title>Veg</title>
                                                            <g fill="none" stroke-width="0.5">
                                                              <rect x="1" y="1" width="14" height="14" stroke="#00a651"/>
                                                            </g>
                                                            <circle cx="8" cy="8" r="4" fill="#00a651"/>
                                                          </svg>
                                                    }
                                                    @if (item.itemAttributeId == '2' || item.itemAttributeId == '24') {
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" version="1.1">
                                                            <title>Non-Veg</title>
                                                            <g fill="none" stroke-width="0.5">
                                                              <rect x="1" y="1" width="14" height="14" stroke="#944a28"/>
                                                            </g>
                                                            <path d="M3.2 12.8h9.6L8 4.8z" fill="#944a28"/>
                                                          </svg>
                                                    }
                                                      
                                                </i> {{item.itemName | removeSpecialCharacter}}</h2>

                                            <ul class="food__dtl__prize rating">
                                                <li class="textPrice">₹ {{item.price | inflatePrice:'25' }}
                                                    <span class="strike-price">{{ item.price }}</span>
                                                </li>
                                                @if(item.itemDescription){
                                                <div class="description-text-wrapper">
                                                    <p class="description-text" [ngClass]="{ 'desScroll': item.readMore == 1 }">
                                                        {{item.itemDescription}}
                                                    </p>
                                                    @if(!item.readMore) {
                                                    <span (click)="item.readMore=1" *ngIf="item.itemDescription.length>35">Read more...</span> }
                                                </div>
                                                }
                                            </ul>

                                        </div>
                                    </div>

                                    @if(item.itemImageUrl != ""){
                                    <div class="food__menu__thumb">
                                        <img [src]="item.itemImageUrl" alt="" style="display: block;margin: auto;" [ngClass]="{ 'OofStock': item.active === '0' }">
                                        <li style="margin-top:-16px;list-style: none;display: flex;justify-content: center;">
                                            <div class="add__to__cart__btn" style="display: inline-block;">
                                                @if(!seletedItemId.includes(item.id)&&item.active === '1'){
                                                <a class="fb food__btn" (click)="selectItem(item)" style="border-radius: 20px;font-size:13px;font-weight: bold;">Add</a> } @if (seletedItemId.includes(item.id) && item.active === '1') {
                                                <a class="fb food__btn" style="border-radius: 20px;font-size:13px;font-weight: bold;padding: 2px 1px;">
                                                    <div class="cart-plus-minus">
                                                        <div class="dec qtybutton" (click)="sameAddonConfirmation(item, $index,'reduce')">-
                                                        </div>
                                                        <input class="cart-plus-minus-box" type="text" name="qtybutton" value="{{item | displayQuantity}}" readonly="true">
                                                        <div class="inc qtybutton" (click)="sameAddonConfirmation(item, $index,'add')">+
                                                        </div>
                                                    </div>
                                                </a>
                                                }
                                            </div>
                                        </li>
                                        @if(item.active === '0'){
                                        <li style=" color: red;list-style: none;" class="customize_txt">Out of Stock</li>
                                        } @if (item.addon.length >0 || item.variation.length >0){
                                        <span class="customize_txt" [ngClass]="{ 'top-0': seletedItemId.includes(item.id) }">Customisable</span> }
                                    </div>
                                    } @if(item.itemImageUrl == ""){
                                    <div class="food__menu__thumb flex-30">

                                        <li style="text-align: right;list-style: none">
                                            <div class="add__to__cart__btn" style="display: inline-block;">
                                                @if(!seletedItemId.includes(item.id)&&item.active === '1'){
                                                <a class="fb food__btn" (click)="selectItem(item)" style="border-radius: 20px;font-size:13px;font-weight: bold;">Add</a> } @if (seletedItemId.includes(item.id) && item.active === '1') {
                                                <a class="fb food__btn" style="border-radius: 20px;font-size:13px;font-weight: bold;padding: 2px 1px;">
                                                    <div class="cart-plus-minus">
                                                        <div class="dec qtybutton" (click)="sameAddonConfirmation(item, $index,'reduce')">-
                                                        </div>
                                                        <input class="cart-plus-minus-box" type="text" name="qtybutton" value="{{item | displayQuantity}}" readonly="true">
                                                        <div class="inc qtybutton" (click)="sameAddonConfirmation(item, $index,'add')">+
                                                        </div>
                                                    </div>
                                                </a>
                                                }
                                            </div>
                                        </li>
                                        @if(item.active === '0'){
                                        <li style=" color: red;list-style: none;" class="customize_txt">
                                            Out of Stock
                                        </li>
                                        } @if (item.addon.length >0 || item.variation.length >0){
                                        <span class="customize_txt" [ngClass]="{ 'top-0': seletedItemId.includes(item.id) }" style="float: right;">Customisable</span> }
                                    </div>
                                    }
                                </div>
                            </div>
                        </div>
                        }
                    </div>

                    } @else {


                    <div class="row">
                        @for (category of menuResponseFiltered; track $index) {
                        <div class="food__category__area sideMenuStyle" [id]="'section-' + $index">
                            <h4 class="side__title">{{category!.categoryName | emptyStringCheck:'Category'}}</h4>
                        </div>

                        @for (item of category.items; track $index) {

                        <div class="col-md-6">
                            <div class="food__menu__container">
                                <div class="food__menu__inner d-flex flex-wrap flex-md-nowrap flex-lg-nowrap">
                                    <div class="food__menu__details">
                                        <div class="food__menu__content" [ngClass]="{ 'out-of-stock': item.active === '0', 'in-stock': item.active === '1' }">
                                            <h2> <i>
                                                @if (item.itemAttributeId == '1') {
                                                    <svg  xmlns="http://www.w3.org/2000/svg" width="16" height="16" version="1.1">
                                                        <title>Veg</title>
                                                        <g fill="none" stroke-width="0.5">
                                                          <rect x="1" y="1" width="14" height="14" stroke="#00a651"/>
                                                        </g>
                                                        <circle cx="8" cy="8" r="4" fill="#00a651"/>
                                                      </svg>
                                                }
                                                @if (item.itemAttributeId == '2' || item.itemAttributeId == '24') {
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" version="1.1">
                                                        <title>Non-Veg</title>
                                                        <g fill="none" stroke-width="0.5">
                                                          <rect x="1" y="1" width="14" height="14" stroke="#944a28"/>
                                                        </g>
                                                        <path d="M3.2 12.8h9.6L8 4.8z" fill="#944a28"/>
                                                      </svg>
                                                }
                                                  
                                            </i> {{item.itemName | removeSpecialCharacter}}</h2>

                                            <ul class="food__dtl__prize rating">
                                                <li class="textPrice">₹ {{item.price | inflatePrice:'25' }}
                                                    <span class="strike-price">{{ item.price }}</span>
                                                </li>
                                                @if(item.itemDescription){
                                                <div class="description-text-wrapper">
                                                    <p class="description-text" [ngClass]="{ 'desScroll': item.readMore == 1 }">
                                                        {{item.itemDescription}}
                                                    </p>
                                                    @if(!item.readMore) {
                                                    <span (click)="item.readMore=1" *ngIf="item.itemDescription.length>35">Read more...</span> }
                                                </div>
                                                }
                                            </ul>

                                        </div>
                                    </div>

                                    @if(item.itemImageUrl != ""){
                                    <div class="food__menu__thumb">
                                        <img [src]="item.itemImageUrl" alt="" style="display: block;margin: auto;" [ngClass]="{ 'OofStock': item.active === '0' }">
                                        <li style="margin-top:-16px;list-style: none;display: flex;justify-content: center;">
                                            <div class="add__to__cart__btn" style="display: inline-block;">
                                                @if(!seletedItemId.includes(item.id)&&item.active === '1'){
                                                <a class="fb food__btn" (click)="selectItem(item)" style="border-radius: 20px;font-size:13px;font-weight: bold;">Add</a> } @if (seletedItemId.includes(item.id) && item.active === '1') {
                                                <a class="fb food__btn" style="border-radius: 20px;font-size:13px;font-weight: bold;padding: 2px 1px;">
                                                    <div class="cart-plus-minus">
                                                        <div class="dec qtybutton" (click)="sameAddonConfirmation(item, $index,'reduce')">-
                                                        </div>
                                                        <input class="cart-plus-minus-box" type="text" name="qtybutton" value="{{item | displayQuantity}}" readonly="true">
                                                        <div class="inc qtybutton" (click)="sameAddonConfirmation(item, $index,'add')">+
                                                        </div>
                                                    </div>
                                                </a>
                                                }
                                            </div>
                                        </li>
                                        @if(item.active === '0'){
                                        <li style=" color: red;list-style: none;" class="customize_txt">Out of Stock</li>
                                        } @if (item.addon.length >0 || item.variation.length >0){
                                        <span class="customize_txt" [ngClass]="{ 'top-0': seletedItemId.includes(item.id) }">Customisable</span> }
                                    </div>
                                    } @if(item.itemImageUrl == ""){
                                    <div class="food__menu__thumb flex-30">

                                        <li style="text-align: right;list-style: none">
                                            <div class="add__to__cart__btn" style="display: inline-block;">
                                                @if(!seletedItemId.includes(item.id)&&item.active === '1'){
                                                <a class="fb food__btn" (click)="selectItem(item)" style="border-radius: 20px;font-size:13px;font-weight: bold;">Add</a> } @if (seletedItemId.includes(item.id) && item.active === '1') {
                                                <a class="fb food__btn" style="border-radius: 20px;font-size:13px;font-weight: bold;padding: 2px 1px;">
                                                    <div class="cart-plus-minus">
                                                        <div class="dec qtybutton" (click)="sameAddonConfirmation(item, $index,'reduce')">-
                                                        </div>
                                                        <input class="cart-plus-minus-box" type="text" name="qtybutton" value="{{item | displayQuantity}}" readonly="true">
                                                        <div class="inc qtybutton" (click)="sameAddonConfirmation(item, $index,'add')">+
                                                        </div>
                                                    </div>
                                                </a>
                                                }
                                            </div>
                                        </li>
                                        @if(item.active === '0'){
                                        <li style=" color: red;list-style: none;" class="customize_txt">
                                            Out of Stock
                                        </li>
                                        } @if (item.addon.length >0 || item.variation.length >0){
                                        <span class="customize_txt" [ngClass]="{ 'top-0': seletedItemId.includes(item.id) }" style="float: right;">Customisable</span> }
                                    </div>
                                    }
                                </div>
                            </div>
                        </div>
                        } }
                    </div>
                    }
                </div>

                <div class="product-wrapper " style="background-color: #e9ecef;border-radius: 12px; ">
                    <div class="food__search " style="padding: 20px 20px; ">
                        <div class="product-wrapper " style="background-color: #e9ecef;border-radius: 12px; ">
                            <div class="food__search  " style="padding: 20px 20px; ">
                                <h4 class="side__title "> {{partnerData?.name | titlecase}}</h4>
                                <div class="deliveryMode ">
                                    <div class="service-category " style="color: #4d4d4d; ">
                                        <i aria-hidden="true " class="fa fa-map-marker " style="color: #d50c0d;padding-right: 10px; "></i>
                                        <span class="address "> {{branchData?.address}} </span><br>
                                        <i aria-hidden="true " class="fa fa-mobile " style="color: #d50c0d;padding-right: 10px; "></i>
                                        <span class="address "> +91 {{branchData?.contact}}</span><br>
                                        <!-- <span class="address ">Fssai- 13623013000801</span><br><br> -->
                                        <div class="address ">
                                            <a href="https://hyperapps.in" target="_blank"> <img src="../../../assets/images/logo/hyperapps-horizontal.png " style="max-width: 250px; " class="footerLogo"></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
@if(this.foodBasket.length >= 0){
<div class="container viewCart ">
    <div class="row ">
        <div class="col-lg-12 col-md-12 col-sm-12 ">
            <div class="grid__show__btn left ">
                <a class="food__btn " (click)="showFoodPagecart() ">
                    <i class="zmdi zmdi-shopping-basket "></i> ₹ {{cartItemPrice | inflatePrice:'25'}}
                    <i class="fa fa-angle-up "></i>
                </a>
            </div>

            @if(this.foodBasket.length > 0){
            <div class="grid__show__btn right ">
                <a class="food__btn " routerLink="/cart" href=" ">View Cart</a>
            </div>
            }
        </div>
    </div>
</div>
} }

<!-- POpup -->
@if (showAddonVariationDialig) {
<app-addonpopup [variations]="variationResponse " [addOnChoice]="addonResponse " [menuItem]="selectedItem.itemName " (addedItem)="getAddedItem($event) "></app-addonpopup>
} @if (sameAddon) {
<div class="accountbox-wrapper is-visible ">
    <div class="body-overlay "></div>
    <div class="accountbox text-left " style="padding: 30px; ">
        <span class="close-icon " title="Close " (click)="sameAddon=! sameAddon ">
            <i class="zmdi zmdi-close "></i>
        </span>
        <ul class="nav accountbox__filters " style="border-bottom: solid 1px #c2c2c2; ">
            <li style="text-align: center;width: 100%;padding: 0px; " class="section__title title__style--2 ">
                <div class="food__menu__content ">
                    <h2 style="font-size:20px;color: #d50c0d;padding-bottom: 10px; ">The selected item has Variation / Addon. Do you want to select the same or New </h2>
                </div>
            </li>
        </ul>
        <hr>
        <div class="accountbox__inner tab-content ">
            <div class="accountbox__login tab-pane fade show active " style="width: 100%; ">
                <div class="single-input " style="text-align: center; ">
                    <button (click)="addItemQuantity( 'same') " type="button " class="food__btn " style="width: 30%;margin-right: 10px;font-size: 15px;padding: 0px; "><span>Use
                            Same</span></button>
                    <button (click)="addItemwithNewAddon() " type="button " class="food__btn " style="width: 30%;font-size: 15px;padding: 0px; "><span>Select New</span></button>
                </div>
            </div>
        </div>
    </div>
</div>
} @if (Showfoodcart) {
<div class="accountbox-wrapper foodcartpopup is-visible ">
    <div class="body-overlay "></div>
    <div class="accountbox text-left ">
        <span class="close-icon " title="Close " (click)="Showfoodcart=! Showfoodcart ">
            <i class="zmdi zmdi-close "></i>
        </span>
        <ul class="nav accountbox__filters " style="border-bottom: solid 1px #c2c2c2; ">
            <li style="text-align: center;width: 100%;padding: 0px; " class="section__title title__style--2 ">
                <div class="food__menu__content ">
                    <h5 style="font-size:16px;color: #d50c0d;padding-bottom: 10px; ">Cart Items </h5>
                </div>
                <div class="row list-wrapper ">
                    @for (basketitem of foodBasket; track $index) {
                    <div class=" col-md-6 col-sm-12 col-xl-12 col-lg-6">
                        <div class="food__menu__container ">
                            <div class="food__menu__inner d-flex flex-wrap flex-md-nowrap flex-lg-nowrap ">

                                <div class="food__menu__details ">
                                    <div class="food__menu__content ">
                                        <div class="product-quantity " style="display: inline-block;float: right; ">
                                            <a class="fb food__btn" style="border-radius: 20px;font-size:13px;font-weight: bold;padding: 2px 1px;    height: auto;">
                                                <div class="cart-plus-minus ">
                                                    <div class="dec qtybutton " (click)="addFromCart(basketitem.item, $index, 'reduce') ">
                                                        -
                                                    </div>
                                                    <input class="cart-plus-minus-box " type="text " name="qtybutton " value="{{basketitem.item.quantity}} " readonly="true ">
                                                    <div class="inc qtybutton " (click)="addFromCart(basketitem.item, $index, 'add') ">
                                                        +
                                                    </div>
                                                </div>
                                            </a>
                                        </div>

                                        <h2 style="display: inline; ">
                                            <i>
                                                @if (basketitem.item.itemAttributeId == '1') {
                                                    <svg  xmlns="http://www.w3.org/2000/svg" width="16" height="16" version="1.1">
                                                        <title>Veg</title>
                                                        <g fill="none" stroke-width="0.5">
                                                          <rect x="1" y="1" width="14" height="14" stroke="#00a651"/>
                                                        </g>
                                                        <circle cx="8" cy="8" r="4" fill="#00a651"/>
                                                      </svg>
                                                }
                                                @if (basketitem.item.itemAttributeId == '2' || basketitem.item.itemAttributeId == '24') {
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" version="1.1">
                                                        <title>Non-Veg</title>
                                                        <g fill="none" stroke-width="0.5">
                                                          <rect x="1" y="1" width="14" height="14" stroke="#944a28"/>
                                                        </g>
                                                        <path d="M3.2 12.8h9.6L8 4.8z" fill="#944a28"/>
                                                      </svg>
                                                }
                                                
                                                  
                                            </i> {{basketitem.item.itemName | removeSpecialCharacter}}</h2>
                                        @if(basketitem.item.addon.length >0 || basketitem.item.variation.length >0) {<br>
                                        <span class="cust-span ">Customizable</span> }
                                        <ul class="food__dtl__prize rating ">
                                            <li>₹ {{basketitem.item.price | inflatePrice:'25'}}
                                                <span class="strike-price">{{ basketitem.item.price }}</span>
                                            </li>
                                            <!-- <li>₹ {{basketitem | showPriceVariation:''}}</li> -->
                                            <li class="li-rigth "></li>
                                            @if (basketitem.item.addon.length >0 || basketitem.item.variation.length >0) { @if(basketitem?.addonVariation?.varients != undefined){
                                            <li class="addon-list ">Varient: {{basketitem.addonVariation.varients.name}}
                                            </li>
                                            } @if(basketitem?.addonVariation?.addOnNames.length > 0 || basketitem?.addonVariation?.VatiationAddOnName.length > 0) {
                                            <li class="addon-list ">
                                                AddOns: @for (item of basketitem?.addonVariation?.addOnNames; track $index; let last = $last) {
                                                <span>{{item}}</span> @if (!last) {<span>, </span>} } @for (item of basketitem?.addonVariation?.VatiationAddOnName; track $index; let last = $last) {
                                                <span>{{item}}</span> @if (!last) {<span>, </span>} }
                                            </li>
                                            } }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    }
                </div>
            </li>
        </ul>

    </div>
</div>
} @if (addrSearch) {
<div class="accountbox-wrapper is-visible ">
    <div class="body-overlay "></div>
    <div class="accountbox text-left " style="padding: 30px;padding-bottom: 50px; ">
        <span class="close-icon " title="Close " (click)="addrSearch=! addrSearch ">
            <i class="zmdi zmdi-close "></i>
        </span>
        <ul class="nav accountbox__filters " style="border-bottom: solid 1px #c2c2c2; ">
            <li style="text-align: center;width: 100%;padding: 0px; " class="section__title title__style--2 ">
                <div class="food__menu__content ">
                    <h2 style="font-size:20px;color: #d50c0d;padding-bottom: 10px; ">Address Search </h2>
                </div>
            </li>
        </ul>
        <hr> @if (unServiceableValue) {
        <span style="font-size:12px;color: #d50c0d; ">{{errorMessage}}</span> }
        <div class="dropdown ">
            <div id="myDropdown " class="dropdown-content ">
                <input type="text " placeholder="Search street, locality " [(ngModel)]="searchTerm " (input)="search() ">
                <i class="fa fa-search "></i>
                <a *ngIf="showResults " style="height: 60vh;overflow-y: auto; ">
                    <div class="result " *ngFor="let result of searchResults " (click)="selectResult(result) " style="cursor: pointer; ">
                        {{ result.text }}
                    </div>
                </a>
            </div>
        </div>

    </div>
</div>
}
<app-store-closed *ngIf="!restaurentActive" (showBranchPopup)="getShowBranchPopUP()"></app-store-closed>
<app-footer></app-footer>