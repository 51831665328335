<app-header [pageName]="currentPage"></app-header>


<div style="min-height: 77vh;"></div>

<div class="accountbox-wrapper is-visible">
    <div class="body-overlay"></div>
    <div class="accountbox text-left popupBG" *ngIf="!otpSent">
        <ul class="nav accountbox__filters popupHeader">
            <li class="section__title title__style--2">
                <a class="active title__line" aria-selected="true" style="font-weight: bold;font-size:24px;color:#fff;">Login</a>
            </li>
        </ul>
        <div class="accountbox__inner popupBody tab-content">
            <div class="accountbox__login tab-pane fade show active" id="log" role="tabpanel" aria-labelledby="log-tab">
                <form [formGroup]="loginForm">
                    <div class="single-input">
                        <input class="cr-round--lg" type="text" placeholder="Name" id="name" formControlName="name" [ngClass]="{ 'is-invalid': submitted && loginFormError['name'].errors }"> @if(submitted && loginFormError['name'].errors){ @if(submitted
                        && loginFormError['name'].errors['required']){
                        <div style="padding-left: 30px;color: #d50c0d;">User Name is required</div>
                        } }
                    </div>


                    <div class="single-input">
                        <input class="cr-round--lg" type="text" placeholder="Whatsapp Number" id="mobile" formControlName="mobile" [ngClass]="{ 'is-invalid': submitted && loginFormError['mobile'].errors }"> @if(submitted && loginFormError['mobile'].errors){
                        @if(submitted && loginFormError['mobile'].errors['required']){
                        <div style="padding-left: 30px;color: #d50c0d;">Mobile No. is required</div>
                        } @if(submitted && loginFormError['mobile'].errors['pattern']){
                        <div style="padding-left: 30px;color: #d50c0d;">Invalid Mobile No.</div>
                        } }
                    </div>

                    <div class="single-input">
                        <button type="submit" class="food__btn" style="width:100%; cursor: pointer;" (click)="submitLoginForm()"><span>Go</span></button>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="accountbox text-left popupBG" *ngIf="otpSent">
        <ul class="nav accountbox__filters popupHeader">
            <li style="text-align: center;width: 100%;padding-top: 0px;" class="section__title title__style--2">
                <a class="active title__line" aria-selected="true" style="font-weight: bold;font-size:24px;color:#fff;">Enter OTP</a>
            </li>
        </ul>
        <div class="accountbox__inner tab-content popupBody">
            <div class="accountbox__login tab-pane fade show active">
                <form [formGroup]="otpForm">
                    <div class="single-input">
                        <input class="cr-round--lg" id="otp" formControlName="otp" maxlength="6" [ngClass]="{ 'is-invalid': otpsubmitted && otpFormError['otp'].errors }"> @if(otpsubmitted && otpFormError['otp'].errors){ @if(otpsubmitted && otpFormError['otp'].errors['required']){
                        <div style="padding-left: 30px;color: #d50c0d;">OTP is required</div>
                        } @if(otpsubmitted && otpFormError['otp'].errors['pattern'] || otpError){
                        <div style="padding-left: 30px;color: #d50c0d;">Invalid OTP</div>
                        } }
                    </div>
                    @if(InvalidOtp){
                    <div style="padding-left: 30px;color: #d50c0d;">Invalid OTP</div>
                    }
                    <div class="single-input">
                        <button type="submit" class="food__btn" style="width:100%;cursor: pointer;" (click)="submitOtpForm()"><span>Verify OTP</span></button>
                    </div>
                    @if (!showResendOTP) {
                    <span style="text-align: center;display: block;padding: 16px;color: #d50c0d;">
                            <countdown [config]="{leftTime: 100, format: 'm:s'}" (event)="timerEvent($event)" ></countdown>
                        </span> } @if (showResendOTP) {
                    <a (click)="resendOtp()" style="text-align: center;display: block;padding: 16px;color: #d50c0d; cursor: pointer;">Resend OTP?</a> }
                </form>
            </div>
        </div>
    </div>
</div>

<app-footer></app-footer>