@if (showHeader) {
<app-header [pageName]="currentPage"></app-header>
}


<div class="mt-8 mb-lg-14 mb-8">
    <div class="container" id="addcustom">
        <div class="row">
            <div class="col-lg-12 col-md-12 mb-6 mb-md-0 b-r" style="min-height: 100vh;">
                <div class="food__sidebar">
                    <div class="food__search mt--30">
                        <h4 class="side__title">Saved Addresses

                            <div class="grid__show__btn grid__show__btn11" style="float: right;">
                                <!-- <a class="food__btn" (click)="openAddress()" style="cursor: pointer;">Add New Address</a> -->
                                <a class="food__btn" (click)="openAddressSearch()" style="cursor: pointer;">Add New Address</a>
                            </div>
                        </h4>
                    </div>
                    <div class="row m-b" style="margin-bottom: 60px;">
                        @for (item of address; let index =$index ;track item) {
                        <div class="col-md-4" [ngClass]="showHeader ? 'col-md-4':'col-md-6'">

                            <div class="food__menu__container" [ngClass]="{ 'food__menu__container-selected': pickedAddressindex == index}">
                                <div class="food__menu__inner d-flex flex-wrap flex-md-nowrap flex-lg-nowrap" (click)="selectedAddress(index)" title="Click to Select Address.">
                                    <div class="food__menu__details" style="padding-left: 10px;width: 100%;">
                                        <div class="food__menu__content">
                                            <h2 style="font-size:14px;">
                                                <section class="fd__service__area">
                                                    <div class="container-fluid">
                                                        <div class="service">
                                                            <div class="service__title">
                                                                <div class="ser__icon">
                                                                    <i class="fa fa-home" style="font-size: 20px;"></i>
                                                                    <h2 style="width: 80%;display: inline-block;    font-size: 18px;
                                                                    color: #d50c0d;">{{item.addressType}}</h2>
                                                                    <i class="fa fa-trash-o" style="font-size: 20px;float: right; color: #d50c0d;  padding-left: 10px;" (click)="deleteAddress(item)" aria-hidden="true"></i>
                                                                    <i class="fa fa-edit" style="font-size: 20px;float: right;" (click)="editAddress(item);$event.stopPropagation();"></i>
                                                                </div>
                                                            </div>
                                                            <div class="service__details">
                                                                <p style="text-transform: initial; margin: 0px;;">
                                                                    {{item.addressOne}}, {{item.addressTwo}}, {{item.landmark}},
                                                                    <br> {{item.city}}, {{item.state}}, {{item.country}} - {{item.pincode}}.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                            </h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        } @if (pickedAddressindex != null && showHeader) {
                        <div class="grid__show__btn grid__show__btn11" style="text-align: right;">
                            <a class="food__btn" (click)="proceedOrder()" style="cursor: pointer;">Proceed</a>
                        </div>
                        }
                    </div>


                </div>
            </div>

        </div>
    </div>

</div>

<app-footer></app-footer>

@if (addNew||editAddr) {
<div class="accountbox-wrapper is-visible">
    <div class="body-overlay"></div>
    <div class="accountbox text-left" style="padding: 30px;">
        <ul class="nav accountbox__filters" style="border-bottom: solid 1px #c2c2c2;">
            <li style="text-align: center;width: 100%;padding: 0px;" class="section__title title__style--2">
                <div class="food__menu__content">
                    <h2 *ngIf="addNew" style="font-size:20px;color: #d50c0d;padding-bottom: 10px;">Add New Address </h2>
                    <h2 *ngIf="editAddr" style="font-size:20px;color: #d50c0d;padding-bottom: 10px;">Edit Address </h2>
                </div>
            </li>
        </ul>
        <hr>
        <div class="accountbox__inner tab-content">
            <div class="accountbox__login tab-pane fade show active" style="width: 100%;">
                <div class="single-input">

                    <form class="billing-form checkout-form" [formGroup]="addressForm">
                        <div class="row">
                            <div class="col-md-6 col-12 mb--20">
                                <input type="hidden" id="id" formControlName="id">
                                <select id="addressType" formControlName="addressType" style="background-color: transparent;border: 1px solid #aaaaaa;    color: #333;padding:0 30px;" [ngClass]="{ 'is-invalid': submitted && addressFormError['addressType'].errors }">
                                      <option value="">Address Type</option>
                                      <option value="Home">Home</option>
                                      <option value="Address">Office</option>
                                    </select> @if(submitted && addressFormError['addressType'].errors){ @if(submitted && addressFormError['addressType'].errors['required']){
                                <div style="padding-left: 30px;color: #d50c0d;">Address Type is required</div>
                                } }
                            </div>
                            <div class="col-md-6 col-12 mb--20">
                                <input type="text" placeholder="Flat No. / Door No. / Floor No." id="flatNo" formControlName="flatNo" [ngClass]="{ 'is-invalid': submitted && addressFormError['flatNo'].errors }"> @if(submitted && addressFormError['flatNo'].errors){
                                @if(submitted && addressFormError['flatNo'].errors['required']){
                                <div style="padding-left: 30px;color: #d50c0d;">Flat No. / Door No. / Floor No. is required</div>
                                } }
                            </div>
                            <div class="col-md-6 col-12 mb--20">
                                <input type="text" placeholder="Address Line 1" id="addressOne" formControlName="addressOne" [ngClass]="{ 'is-invalid': submitted && addressFormError['addressOne'].errors }"> @if(submitted && addressFormError['addressOne'].errors){
                                @if(submitted && addressFormError['addressOne'].errors['required']){
                                <div style="padding-left: 30px;color: #d50c0d;">Address Line 1 is required</div>
                                } }
                            </div>
                            <div class="col-md-6 col-12 mb--20">
                                <input type="text" placeholder="Address Line 2" id="addressTwo" formControlName="addressTwo" [ngClass]="{ 'is-invalid': submitted && addressFormError['addressTwo'].errors }"> @if(submitted && addressFormError['addressTwo'].errors){
                                @if(submitted && addressFormError['addressTwo'].errors['required']){
                                <div style="padding-left: 30px;color: #d50c0d;">Address Line 2 is required</div>
                                } }
                            </div>
                            <div class="col-md-6 col-12 mb--20">
                                <input type="text" placeholder="Land Mark" id="landmark" formControlName="landmark" [ngClass]="{ 'is-invalid': submitted && addressFormError['landmark'].errors }"> @if(submitted && addressFormError['landmark'].errors){
                                @if(submitted && addressFormError['landmark'].errors['required']){
                                <div style="padding-left: 30px;color: #d50c0d;">Land Mark is required</div>
                                } }
                            </div>
                            <div class="col-md-6 col-12 mb--20">
                                <input placeholder="City" type="text" id="city" formControlName="city" readonly [ngClass]="{ 'is-invalid': submitted && addressFormError['city'].errors }"> @if(submitted && addressFormError['city'].errors){ @if(submitted && addressFormError['city'].errors['required']){
                                <div style="padding-left: 30px;color: #d50c0d;">City is required</div>
                                } }
                            </div>
                            <div class="col-md-6 col-12 mb--20">
                                <input type="text" placeholder="State" id="state" formControlName="state" readonly [ngClass]="{ 'is-invalid': submitted && addressFormError['state'].errors }"> @if(submitted && addressFormError['state'].errors){ @if(submitted &&
                                addressFormError['state'].errors['required']){
                                <div style="padding-left: 30px;color: #d50c0d;">State is required</div>
                                } }
                            </div>
                            <div class="col-md-6 col-12 mb--20">
                                <input type="text" placeholder="Country" id="country" formControlName="country" readonly [ngClass]="{ 'is-invalid': submitted && addressFormError['country'].errors }" [disabled]="true"> @if(submitted && addressFormError['country'].errors){
                                @if(submitted && addressFormError['country'].errors['required']){
                                <div style="padding-left: 30px;color: #d50c0d;">Country is required</div>
                                } }
                            </div>
                            <div class="col-md-6 col-12 mb--20">
                                <input placeholder="PIN Code" type="text" id="pincode" formControlName="pincode" [readonly]="blockEditPincode" [ngClass]="{ 'is-invalid': submitted && addressFormError['pincode'].errors }"> @if(submitted && addressFormError['pincode'].errors){ @if(submitted
                                && addressFormError['pincode'].errors['required']){
                                <div style="padding-left: 30px;color: #d50c0d;">PIN Code is required</div>
                                }@if(submitted && addressFormError['pincode'].errors['pattern']){
                                <div style="padding-left: 30px;color: #d50c0d;">Invalid PIN Code</div>
                                } }
                            </div>
                            <div class="col-md-6 col-12 mb--20">
                            </div>

                            <div class="col-md-3 col-12 mb--20">
                                <div class="grid__show__btn">
                                    <a *ngIf="addNew" class="food__btn" style="cursor: pointer;width:100%;text-align: center;" (click)="addAddress()">Save</a>
                                    <a *ngIf="editAddr" class="food__btn" style="cursor: pointer;width:100%;text-align: center;" (click)="updateAddress()">Update</a>
                                </div>
                            </div>
                            <div class="col-md-3 col-12 mb--20">
                                <div class="grid__show__btn">
                                    <a class="food__btn" style="cursor: pointer;width:100%;text-align: center;    background-color: transparent;
                                    color: #d50c0d;
                                    border:solid 1px #d50c0d;" (click)="closeAddressForm()">Close</a>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
} @if (addrSearch) {
<div class="accountbox-wrapper is-visible">
    <div class="body-overlay"></div>
    <div class="accountbox text-left" style="padding: 30px;padding-bottom: 50px;">
        <span class="close-icon" title="Close" (click)="addrSearch = !addrSearch">
            <i class="zmdi zmdi-close"></i>
        </span>
        <ul class="nav accountbox__filters" style="border-bottom: solid 1px #c2c2c2;">
            <li style="text-align: center;width: 100%;padding: 0px;" class="section__title title__style--2">
                <div class="food__menu__content">
                    <h2 style="font-size:20px;color: #d50c0d;padding-bottom: 10px;">Address Search </h2>
                </div>
            </li>
        </ul>
        <hr> @if (unServiceableValue) {
        <span style="font-size:12px;color: #d50c0d;">{{errorMessage}}</span> }
        <div class="accountbox__inner tab-content">
            <div class="accountbox__login tab-pane fade show active" style="width: 100%;">
                <div class="single-input">

                    <div class="dropdown">
                        <div id="myDropdown" class="dropdown-content">
                            <input type="text" placeholder="Search street, locality" [(ngModel)]="searchTerm" (input)="search()">
                            <i class="fa fa-search"></i>
                            <a *ngIf="showResults">
                                <div class="result" *ngFor="let result of searchResults" (click)="selectResult(result)" style="cursor: pointer;">
                                    {{ result.text }}
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
} @if(checkDeleteAddress){
<div class="accountbox-wrapper is-visible">
    <div class="body-overlay"></div>
    <div class="accountbox text-left" style="padding: 30px;">
        <span class="close-icon" title="Close" (click)="checkDeleteAddress = !checkDeleteAddress">
                <i class="zmdi zmdi-close"></i>
            </span>
        <ul class="nav accountbox__filters" style="border-bottom: solid 1px #c2c2c2;">
            <li style="text-align: center;width: 100%;padding: 0px;" class="section__title title__style--2">
                <div class="food__menu__content">
                    <h2 style="font-size:20px;color: #d50c0d;padding-bottom: 10px;">Are you sure you want to delete ? </h2>
                </div>
            </li>
        </ul>
        <hr>
        <div class="accountbox__inner tab-content">
            <div class="accountbox__login tab-pane fade show active" style="width: 100%;">
                <div class="single-input" style="text-align: center;">
                    <button (click)="confirmDeleteAddress()" type="button" class="food__btn" style="width: 30%;margin-right: 10px;font-size: 15px;padding: 0px;"><span>Confirm</span></button>
                    <button (click)="checkDeleteAddress = !checkDeleteAddress" type="button" class="food__btn" style="width: 30%;font-size: 15px;padding: 0px;"><span>Cancel</span></button>
                </div>
            </div>
        </div>
    </div>
</div>
}
<p-toast></p-toast>