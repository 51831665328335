import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { InflatePricePipe } from "../../../core/pipes/inflate-price.pipe";

@Component({
  selector: 'app-addonpopup',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, InflatePricePipe],
  templateUrl: './addonpopup.component.html',
  styleUrl: './addonpopup.component.scss'
})
export class AddonpopupComponent implements OnInit {

  @Input() menuItem: string = '';
  @Input() variations: any = {};
  @Input() addOnChoice: any = {};
  @Output() addedItem = new EventEmitter<any>();
  variation: any = '';
  selectedVarient: number = 0;
  tvariations: any = {};
  taddOnChoice: any = {};
  totalPrice: number = 0;

  variationForm!: FormGroup;
  addonForm!: FormGroup;

  constructor(private formBuilder: FormBuilder) {
    this.variationForm = this.formBuilder.group({
      addOngrp: this.formBuilder.array([])
    });
    this.addonForm = this.formBuilder.group({
      addOngrp: this.formBuilder.array([])
    });
  }


  ngOnInit(): void {

    console.log(this.variations, this.addOnChoice);

    this.tvariations = this.variations.flat().sort((a:any, b:any) => a.price - b.price);
    // this.tvariations = this.variations.flatMap((ele: any) => ele.concat());
    this.taddOnChoice = this.addOnChoice.flatMap((ele: any) => ele.concat());
    // this.addFormControl();

    this.createForm();
    this.valueUpdate();
    this.totalPrice = parseFloat(this.tvariations[this.selectedVarient]?.price);

    // console.log(this.variationForm.controls);

  }

  selectVariet(index: number) {
    this.selectedVarient = index;
    this.totalPrice = parseFloat(this.tvariations[this.selectedVarient]?.price);
    // this.addFormControl();
    // this.variationForm.reset();
    this.clearAllForms();
    this.createForm();
    // this.valueUpdate();
    console.log(this.variationForm.controls);

  }

  clearAllForms():void {
    this.variationForm = this.formBuilder.group({
      addOngrp: this.formBuilder.array([])
    });
    this.addonForm = this.formBuilder.group({
      addOngrp: this.formBuilder.array([])
    });
  }
  createForm() {
    // this.variationForm.reset();
    if (this.variations.length > 0) {
      console.log(this.tvariations[this.selectedVarient]);

      this.tvariations[this.selectedVarient].addonGroups.map((grpEle: any, grpIndex: number) => {
        this.newGroup();
        grpEle.addonItems.map((ele: any, addonindex: number) => {
          this.getaddonControl(grpIndex).push(this.newaddongroup());
          this.createFormControl(grpIndex, addonindex, ele.id)
          // this.addedItemcontrol(index, ele.id)
        })
        // grpEle.map((ele:any) =>{
        //   console.log(ele);
        // });
      });

    }

    if (this.taddOnChoice.length > 0) {
      this.taddOnChoice[0].addonItems.forEach((element: any, index: number) => {
        this.getadOnchoicegroup().controls.push(this.newaddongroup());
        (<FormGroup>this.getadOnchoicegroup().controls[index]).addControl(element.id, new FormControl(false))
      });


    }

  }

  newGroup(): void {
    this.getadOngroup().push(this.addGroup());
  }
  addGroup(): FormGroup {
    return this.formBuilder.group({
      addons: this.formBuilder.array([])
    });
  }
  newaddongroup(): FormGroup {
    return this.formBuilder.group({});
  }
  getadOngroup(): FormArray {
    return this.variationForm.get('addOngrp') as FormArray;
  }

  getaddonControl(grpindex: number) {
    return (<FormArray>(<FormGroup>this.getadOngroup().controls[grpindex]).controls['addons']).controls;
  }
  createFormControl(grpindex: number, addonindex: number, ctrl: string) {
    (<FormGroup>(<FormArray>(<FormGroup>this.getadOngroup().controls[grpindex]).controls['addons']).controls[addonindex]).addControl(ctrl, new FormControl(false));
  }

  getadOnchoicegroup(): FormArray {
    return this.addonForm.get('addOngrp') as FormArray;
  }
  // -----------------------------
  /**
   * 
   * OLD CODE IF INCASE NEED
   * 
   */
  getgroupControl(index: number): any {
    return this.getadOngroup()
      .at(index)
      .get('addons') as FormArray;

    // groupControl.controls.addons.push(this.addedItemcontrol(ctrol))

  }

  addedItemcontrol(index: number, ctrl: string) {
    console.log((<FormGroup>this.getadOngroup().controls[index]).controls);

    // (<FormArray>(<FormGroup>this.getdOngroup().controls[index]).controls['addons']).controls.push(this.addItemctrlGroup());
    // (<FormArray>(<FormGroup>this.getdOngroup().controls[index]).controls['addons']).controls.push(new FormControl());

    // this.getgroupControl(index).controls.push({[ctrl]: new FormControl(false)});
    // this.tvariations[this.selectedVarient].addonGroups[0].addonItems.map((ele:any) => {
    //   this.variationForm.addControl(ele.id, new FormControl(false));
    // })
  }
  addItemctrlGroup() {
    return this.formBuilder.group({})
  }
  //OLD CODE ENDS
  // ----------------------------
  valueUpdate() {

    this.totalPrice = parseFloat(this.tvariations[this.selectedVarient]?.price);
    this.variationForm.valueChanges.subscribe((value: any) => {

      console.log("FORMVALUE", value);
      // this.tvariations[this.selectedVarient].addonGroups[0].addonItems.map((ele:any) =>{
      //   if(value[ele.id] == true){
      //    this.totalPrice = this.totalPrice + parseFloat(ele.addonItemPrice) ;
      //   }
      // })
    });

    // console.log(this.tvariations[this.selectedVarient].addonGroups[0].addonItems);
  }

  addCart() {
    let selecetdVerAddonList: any = [];
    let selecetdAddonList: any = [];

    let selectedVarientValue = this.variationForm.getRawValue();
    let selectedAddonValue = this.addonForm.getRawValue();
    // console.log(selectedVarientValue, this.tvariations);
    // console.log(selectedAddonValue, this.taddOnChoice);

    selectedVarientValue!.addOngrp.forEach((element: any, index: number) => {
      element.addons.forEach((adonEle: any, innerIndex: number) => {
        if (Object.values(adonEle)[0])
          selecetdVerAddonList.push(this.tvariations[0].addonGroups[index].addonItems[innerIndex].addonItemName);
      });

    });

    selectedAddonValue!.addOngrp.forEach((ele: any, index: number) => {
      if (Object.values(ele)[0])
        selecetdAddonList.push(this.taddOnChoice[0].addonItems[index].addonItemName);

    })

    const addonVariation = {
      varients: this.tvariations[this.selectedVarient],
      variationaddOns: this.variationForm.getRawValue(),
      VatiationAddOnName: selecetdVerAddonList,
      addons: this.addonForm.getRawValue(),
      addonDetails: this.taddOnChoice,
      addOnNames: selecetdAddonList
    }
    // console.log(addonVariation);

    this.addedItem.emit({ action: "add", addonVariation });
    this.variations = [];
    this.addOnChoice = [];
    this.clearAllForms();
  }
  closeDialogue() {
    this.addedItem.emit({ action: 'cancel' });
    this.variations = [];
    this.addOnChoice = [];
    this.clearAllForms();
  }
}
